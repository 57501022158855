import emailjs from 'emailjs-com';

export default function ContactMe() {
  const handleSubmit = (event) => {
    event.preventDefault();

    var formData = {
      "first-name": document.getElementById('first-name').value,
      "last-name": document.getElementById('last-name').value,
      "email": document.getElementById('email').value,
      "phone-number": document.getElementById('phone-number').value,
      "choose-topic": document.getElementById('choose-topic').value,
      "message": document.getElementById('message').value,
    }

    emailjs.send('service_20cvhuw', 'template_954xkup', formData,"5TInWOsJClMBR8QWD")
      .then((response) => {
        console.log('Email sent successfully:', response);

        alert('Email sent successfully! Thank you for contacting me!');
        window.location.href = '/';
      })
      .catch((error) => {
        console.error('Email sending failed:', error);
        alert('Email sending failed! Please try again later!');
      });

  };
  return (
    <section id="Contact" className="contact--section">
      <div>
        <p className="sub--title">Get In Touch</p>
        <h2>Contact Me</h2>
        <p className="text-lg">
          If you have any questions, please feel free to contact me. I'll love to connect with you!
        </p>
      </div>
      <form className="contact--form--container">
        <div className="container">
          <label htmlFor="first-name" className="contact--label">
            <span className="text-md">First Name</span>
            <input
              type="text"
              className="contact--input text-md"
              name="first-name"
              id="first-name"
              required
            />
          </label>
          <label htmlFor="last-name" className="contact--label">
            <span className="text-md">Last Name</span>
            <input
              type="text"
              className="contact--input text-md"
              name="last-name"
              id="last-name"
              required
            />
          </label>
          <label htmlFor="email" className="contact--label">
            <span className="text-md">Email</span>
            <input
              type="email"
              className="contact--input text-md"
              name="email"
              id="email"
              required
            />
          </label>
          <label htmlFor="phone-number" className="contact--label">
            <span className="text-md">phone-number</span>
            <input
              type="number"
              className="contact--input text-md"
              name="phone-number"
              id="phone-number"
              required
            />
          </label>
        </div>
        <label htmlFor="choode-topic" className="contact--label">
          <span className="text-md">Purpose for contact</span>
          <select id="choose-topic" className="contact--input text-md">
            <option>Select One...</option>
            <option>Job opportunities</option>
            <option>Connect with me</option>
          </select>
        </label>
        <label htmlFor="message" className="contact--label">
          <span className="text-md">Message</span>
          <textarea
            className="contact--input text-md"
            id="message"
            rows="8"
            placeholder="Type your message..."
          />
        </label>
        <label htmlFor="checkboc" className="checkbox--label">
          <input type="checkbox" required name="checkbox" id="checkbox" />
          <span className="text-sm">This will send an notification email to Chris Chau</span>
        </label>
        <div>
          <button className="btn btn-primary contact--form--btn" onClick={handleSubmit}>Submit</button>
        </div>
      </form>
    </section>
  );
}
