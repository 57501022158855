export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/about-me.png" alt="About Me" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <p className="section--title">About</p>
          <h1 className="skills-section--heading">About Me</h1>
          <p className="hero--section-description">
            I'm a student majoring in Software Engineering at Brigham Young University, currently working as a computer support representative for the Risk & Management department at BYU. I'm really into full-stack software development, especially in web and Artificial Intelligence. I know teamwork and communication are crucial, and I'm a hard worker, self-motivated, and a quick learner. I'm always eager for new chances to learn and improve.
          </p>
          <p className="hero--section-description">

            A fun fact about me is that I'm a musician. I play the piano, violin, and harmonica. In my free time, I enjoy playing video games. I'm fluent in English, Mandarin, and Cantonese.
          </p>
        </div>
      </div>
    </section>
  );
}
